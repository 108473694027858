* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.small-nav {
  --nav-width: 80px;
}

.avg-nav {
  --nav-width: 200px;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--nav-width);
  height: 100vh;
  background: #111;
  transition: 0.3s;
}

nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  list-style: none;
  overflow: hidden;
  gap: 10px;
  padding: 10px;
}

nav ul li {
  width: 100%;
  padding: 24px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 12px;
  font-size: 18px;
  transition: 0.1s;
}

nav ul li img {
  width: 25px;
  height: 25px;
}

nav ul li a {
  text-decoration: none;
}

nav ul li:hover {
  opacity: 0.5;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--nav-width);
  background: #111;
  transition: 0.2s;
}

.bottom li {
  width: 100%;
  padding: 25px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  transition: 0.2s;
}

.bottom li:hover {
  opacity: 0.5;
}

.bottom li img {
  width: 25px;
  height: 25px;
}

.container {
  position: relative;
  width: calc(100% - var(--nav-width));
  backdrop-filter: blur(2px);
  transition: 0.2s;
  left: var(--nav-width);
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.411)) no-repeat center center fixed;
}

@media screen and (max-width: 1000px) {
  .small-nav {
    --nav-width: 100vw;
  }

  .avg-nav {
    --nav-width: 100vw;
  }

  nav {
    width: var(--nav-width);
    bottom: 0;
    left: 0;
    top: auto;
    position: fixed;
    transition: 0.3s;
    height: fit-content;
    z-index: 1;
  }

  nav ul {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 0;
    padding: 20px 0;
  }

  nav ul li {
    padding: 12px;
  }

  .bottom {
    display: none;
  }

  .container {
    height: max-content;
    width: 100vw;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 80px;
    padding: 1rem 0.8rem;
  }
}