.display {
    display: flex;
    flex-direction: column;
    width: calc(100vw - var(--nav-width));
    height: 100vh;
}

.display h1 {
    color: #fff;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    padding: 2rem;
}

.vodoravno {
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    padding: 4rem 0;
}

.location {
    color: #fff;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
}

.location .a-text {
    font-size: 4rem;
    font-weight: 250;
}

.temp {
    color: #fff;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.temp .a-text {
    font-size: 3rem;
    font-weight: 250;
}

.temp p {
    font-size: 1.5rem;
    font-weight: 300;
}

.condition {
    color: #fff;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.remainingForecast {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    max-width: 40%;
}

.remainingForecast .a-text {
    font-size: 1.4rem;
    font-weight: 250;
    color: #fff;
    margin: 0.4rem 0;
}

.remainingForecast .scrollbar {
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    color: #fff;
    width: 100%;
    scrollbar-width: none;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
}

.remainingForecast .remainingHour {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 1.4rem;
    color: #fff;
    background: rgba(20, 20, 20, 0.473);
    padding: 1.8rem;
    scroll-snap-align: center;
}

.remainingForecast .remainingHour:hover {
    background: rgba(20, 20, 20, 0.8);
    cursor: pointer;
}

.remainingForecast .remainingHour .a-text {
    font-size: 1.2rem;
    font-weight: 250;
    color: #fff;
    margin: 0;
}

.remainingForecast .remainingHour img {
    width: 80px;
    height: 80px;
}

.astro {
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.astro .a-text {
    font-size: 1.5rem;
    font-weight: 250;
}

.astro p {
    font-size: 1rem;
    font-weight: 300;
}

@media screen and (max-width: 1024px) {
    .display {
        width: 100%;
        height: 100%;
        padding-bottom: 20px;
    }

    .display h1{
        display: none;
    }

    .vodoravno {
        flex-direction: column;
        gap: 2rem;
        padding: 0;
        margin: 0 auto;
    }

    .remainingForecast {
        max-width: 85vw;
        overflow-x: scroll;
    }

    .remainingForecast .navodilo{
        display: none;
    }
}