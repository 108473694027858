.displayCurrent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
    padding: 0 1rem;
}

.basic {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.location {
    color: #fff;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
}

.location a {
    font-size: 4rem;
}

.temperatura {
    color: #fff;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.tempertatura a {
    font-size: 3rem;
    font-weight: 250;
}

.other {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
}

.other div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.other-title {
    font-size: 2.2rem;
    font-weight: 320;
    margin-bottom: 1rem;
}

.lastUpdated {
    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .displayCurrent {
        padding: 0 1rem;
        height: calc(100vh - 112px);
    }

    .basic {
        flex-direction: column;
        gap: 2rem;
    }

    .other {
        flex-direction: column;
        gap: 2rem;
    }
}