.displayForecast {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    height: 100%;
}

.displayForecast .a-text {
    text-decoration: none;
    color: #fff;
    font-size: 2em;
    font-weight: 300;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.days {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    padding: 0;
    border-radius: 0;
    width: 100%;
}

.days .day {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: rgba(20, 20, 20, 0.5);
    color: #fff;
    font-weight: 300;
    font-size: 1.2em;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    padding: 20px 30px;
}

.day .astro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    width: 100%;
}

.day .astro .a-text {
    text-decoration: none;
    color: #fff;
    font-size: 1.2em;
    font-weight: 300;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.hourly {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    border-radius: 2px;
    width: 100%;
}

.hourly .hours {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    border-radius: 2px;
    width: 100%;
    height: 30vh;
    overflow-y: scroll;
    cursor: n-resize;
}

.hourly .hours .hour {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    font-weight: 300;
    font-size: 1.2em;
    width: 100%;
    gap: 20px;
    padding: 10px;
}

.hourly .hours .hour .hourDisplay {
    font-size: 2rem;
}

.hourly .hours .hour .a-text {
    text-decoration: none;
    color: #fff;
    font-size: 1.2em;
    font-weight: 300;
}

@media screen and (max-width: 1024px) {
    .displayForecast {
        padding: 10px;
    }

    .days {
        gap: 10px;
        font-size: 1em;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .days .day {
        padding: 5px;
        width: 100%;
    }

    .day .a-text {
        font-size: 2em;
    }

    .day .astro {
        gap: 5px;
        padding: 5px;
    }

    .day .astro .a-text {
        font-size: 1.5em !important;
    }

    .hourly {
        padding: 5px;
    }

    .hourly .hours {
        gap: 5px;
        padding: 5px;
    }

    .hourly .hours .hour {
        gap: 10px;
        padding: 5px;
        font-size: 1em;
        justify-content: space-evenly;
    }
}